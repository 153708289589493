<template>
    <div class="animation fadeIn">
      <div id="snackbar"></div>
      <b-row>
          <b-col sm="12">
              <b-card>
                  <b-form v-on:submit.prevent.self="submit">
                    <b-row>

                      <b-col>
                        <label for="TemplateTitle">Content Title</label>
                        <b-form-group>
                            <b-form-input type="text" required v-model="content.ContentName" placeholder="Title"></b-form-input>
                        </b-form-group>
                      </b-col>
                       
                    </b-row>

                  
                    <b-row>
                      <b-col>
                        <b-form-group>
                          <label for="TemplateBody">Content</label>
                            <vue-editor v-model="content.Content"></vue-editor>
                        </b-form-group> 
                      </b-col>                   
                    </b-row>

                    <b-row>
                      <b-col sm="6">
                        <b-form-group>
                          <b-button type="submit" variant="primary" size="sm">
                              <i class="fa fa-comment-o">
                              </i>
                              {{ commontext.submitButton }}
                          </b-button>
                        </b-form-group>
                      </b-col>

                      <b-col sm="6">
                        <b-button @click="resetForm" size="sm" variant="success"><i class="fa fa-dot-circle-o"></i> Reset</b-button>
                    </b-col>
                    </b-row>
                  </b-form>


              </b-card>

            <!-- List of templates -->
            <v-client-table :columns="columns" :data="data" :options="options" :theme="theme" id="dataTable">
            <div slot="ContentID" slot-scope="props">
                <i class="fa fa-edit" @click="editContent(props.row.ContentID)"></i>
              </div>

            <div slot="Url" slot-scope="props">
              <a :href="props.row.Url" target="_blank"><i class="icon-eye"></i></a> 
            </div>

            </v-client-table>
              <!-- End List -->
          </b-col>
      </b-row>
       <b-modal id="ModalConfirmationOnSubmit" ref="ModalConfirmationOnSubmit" @ok="handleModalOkay" :title="modalObject.title" class="center-footer" footer-class="justify-content-center border-0" cancel-variant="outline-danger" ok-variant="outline-success" centered hide-header no-close-on-esc no-close-on-backdrop >
        <div class="d-block text-center">
          <div>{{ modalObject.message }}</div>
        </div>
      </b-modal>
  </div>
</template>
<script>
import Vue from "vue";
import { Switch as cSwitch } from "@coreui/vue";
import { VueEditor } from "vue2-editor";
import MasterService from "@/services/MasterService";
import { Event } from "vue-tables-2";
export default {
  name: "AddContent",
  components: {
    cSwitch,
    VueEditor,
    Event
  },

  data() {
    return {
      commontext: {
        submitButton: "Add Content"
      },
      content: {
        Flag: 1,
        ContentID: null,
        ContentName: "",
        Content: null
      },
      templateTypes: [],
      labelIcon: {
        dataOn: "\u2713",
        dataOff: "\u2715"
      },
      columns: ["ContentName", "ContentID", "Url"],
      data: [],
      options: {
        headings: {
          ContentName: "Content Title",
          ContentID: "Edit",
          Url: "Preview"
        },
        sortable: ["ContentName"],
        filterable: ["ContentName"],
        sortIcon: {
          base: "fa",
          up: "fa-sort-asc",
          down: "fa-sort-desc",
          is: "fa-sort"
        },
        pagination: {
          chunk: 5,
          edge: false,
          nav: "scroll"
        }
      },
      useVuex: false,
      theme: "bootstrap4",
      template: "default"
    };
  },
  mounted() {
    this.getContentList();
  },
  methods: {
    resetForm: function() {
      this.content = {
        Flag: 1,
        ContentID: null,
        ContentName: "",
        Content: null
      };
      this.commontext.submitButton = "Add Content";
    },
    getContentList: function() {
      this.data = [];
      MasterService.getContentList()
        .then(response => {
          const { data } = response;
          if (data.Status && data.ContentList.length > 0) {
            this.data = data.ContentList.reverse();
          } else {
            this.showMessageSnackbar(data.Message);
          }
        })
        .catch(error => {
          this.showMessageSnackbar(
            "Oops! Something went wrong. kindly contact your administrator"
          );
        });
    },
    validation: function() {
      if (this.content.ContentName == "") {
        this.showMessageSnackbar("Content Name Cannot be empty.");
        return false;
      } else if (this.content.Content == "") {
        this.showMessageSnackbar("Content Cannot be empty.");
        return false;
      } else {
        return true;
      }
    },
    submit: function() {
      if (this.validation()) {
        let payload = {
          title: "Confirmation",
          message: "Please re-check the data before submit"
        };
        this.showSubmitConfirmation(payload);
      }
    },
    submitFinalData: function() {
      this.closeModal("ModalConfirmationOnSubmit");

      MasterService.addContent(this.content)
        .then(response => {
          const { data } = response;
          this.getContentList();
          data.Message ? this.showMessageSnackbar(data.Message) : "";
          if (data.Status) {
            this.resetForm();
          }
        })
        .catch(error => {
          this.showMessageSnackbar(
            "Oops! Something went wrong. kindly contact your administrator"
          );
        });
    },

    editContent: function(ContentID) {
      // let res = await MasterService.getContentByID(ContentID);
      // this.content = res.data[0];
      let payload = {
        ContentID
      };
      MasterService.getContentByID(payload)
        .then(response => {
          const { data } = response;
          if (data.Status && data.ContentList.length > 0) {
            this.content = data.ContentList[0];
            this.content.Flag = 2;
            this.commontext.submitButton = "Update Content";
          } else {
            this.showMessageSnackbar(data.Message);
          }
        })
        .catch(error => {
          this.showMessageSnackbar(
            "Oops! Something went wrong. kindly contact your administrator"
          );
        });
    },
    // Show Confirmmation Dialog
    handleModalOkay: function(evt) {
      // // evt.preventDefault();
      this.modalObject.count = 1;
      this.submitFinalData();
    },
    showSubmitConfirmation: function(payload) {
      this.modalObject.title = payload.title; // "Confirmation";
      this.modalObject.message = payload.message; // "Please re-check the data before submit";
      this.openModal("ModalConfirmationOnSubmit");
    },
    openModal: function(ref) {
      this.$refs[ref].show();
    },
    closeModal: function(ref) {
      this.$refs[ref].hide();
      this.modalObject.title = ""; // "Confirmation";
      this.modalObject.message = ""; // "Please re-check the data before submit";
    },
    // Show Confirmmation Dialog End
    // Show Error Message
    showMessageSnackbar: function(message) {
      let x = document.getElementById("snackbar");
      x.className = "show";
      x.innerHTML = message;
      setTimeout(function() {
        x.className = x.className.replace("show", "");
      }, 3000);
    }
  }
};
</script>
<style>
.fa-edit {
  cursor: pointer;
  font-size: 18px;
}
.fa-edit:hover {
  color: #20a8d8;
}
</style>
